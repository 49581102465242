const baseURL = process.env.REACT_APP_BACKEND_DOMAIN;

const getHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    Authorization: token || '',
  };
};

const handleResponse = async (res: any) => {
  if (!res.ok) {
    const errorBody = await res.json();
    if (res.status === 400) {
      throw new Error(`Bad Request: ${errorBody.message}`);
    } else if (res.status === 401) {
      localStorage.removeItem('token');
      window.location.href = 'auth/login';
      throw new Error(`Unauthorized: ${errorBody.message}`);
    } else if (res.status === 404) {
      throw new Error(`Not Found: ${errorBody.message}`);
    } else {
      throw new Error(`HTTP Error: ${res.status} - ${errorBody.message}`);
    }
  }
  return res.json();
};

export const userLogin = async (body: any) => {
  try {
    const res = await fetch(`${baseURL}/api/auth/login`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    return res;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const getAllUsers = async () => {
  const token = localStorage.getItem('token');

  try {
    const res = await fetch(`${baseURL}/api/auth/users`, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token || '',
      },
    });

    return handleResponse(res);
  } catch (error) {
    console.error('Error getting all users:', error);
    throw error;
  }
};

export const addUser = async (body: any) => {
  try {
    const res = await fetch(`${baseURL}/api/auth/add-user`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    const data = res.json();
    return data;
  } catch (error) {
    console.error('Error adding user:', error);
    throw error;
  }
};

export const updateUser = async (id: any, body: any) => {
  try {
    const res = await fetch(`${baseURL}/api/auth/update-user/${id}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    const data = res.json();
    return data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const deleteUser = async (id: any) => {
  try {
    const res = await fetch(`${baseURL}/api/auth/delete-user/${id}`, {
      method: 'DELETE',
      headers: getHeaders(),
    });

    return handleResponse(res);
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export const addProduct = async (body: any) => {
  try {
    const res = await fetch(`${baseURL}/api/products/add-product`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    });
    const data = await res?.json();
    return data;
  } catch (error) {
    console.error('Error adding product:', error);
    throw error;
  }
};

export const getAllProducts = async () => {
  const token = localStorage.getItem('token');
  try {
    const res = await fetch(`${baseURL}/api/products/all-products`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token || '',
      },
    });

    return handleResponse(res);
  } catch (error) {
    console.error('Error getting all products:', error);
    throw error;
  }
};

export const updateProduct = async (id: any, body: any) => {
  try {
    const res = await fetch(`${baseURL}/api/products/update-product/${id}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    return handleResponse(res);
  } catch (error) {
    console.error('Error updating product:', error);
    throw error;
  }
};

export const deleteProduct = async (id: any) => {
  try {
    const res = await fetch(`${baseURL}/api/products/delete-product/${id}`, {
      method: 'DELETE',
      headers: getHeaders(),
    });

    return handleResponse(res);
  } catch (error) {
    console.error('Error deleting product:', error);
    throw error;
  }
};

export const addProductVariant = async (body: any) => {
  try {
    const res = await fetch(`${baseURL}/api/variants/add-variant`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    });
    return handleResponse(res);
  } catch (error) {
    console.error('Error adding product variant:', error);
    throw error;
  }
};

export const updateVariant = async (id: any, body: any) => {
  try {
    const res = await fetch(`${baseURL}/api/variants/update-variant/${id}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(body),
    });
    return handleResponse(res);
  } catch (error) {
    console.error('Error updating variant:', error);
    throw error;
  }
};

export const deleteVariant = async (id: any) => {
  try {
    const res = await fetch(`${baseURL}/api/variants/delete-variant/${id}`, {
      method: 'DELETE',
      headers: getHeaders(),
    });
    return handleResponse(res);
  } catch (error) {
    console.error('Error deleting variant:', error);
    throw error;
  }
};

export const getVariantById = async (id: any) => {
  try {
    const res = await fetch(`${baseURL}/api/variants/variant-by-id/${id}`, {
      method: 'GET',
      headers: getHeaders(),
    });
    return handleResponse(res);
  } catch (error) {
    console.error('Error getting variant:', error);
    throw error;
  }
};

export const getProductById = async (id: any) => {
  try {
    const res = await fetch(`${baseURL}/api/products/product-by-id/${id}`, {
      method: 'GET',
      headers: getHeaders(),
    });
    return handleResponse(res);
  } catch (error) {
    console.error('Error getting product:', error);
    throw error;
  }
};
